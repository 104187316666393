.photography-page-container {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 6rem;
}

/* Photography Content Styles */
.photography-content {
  text-align: center;
}

.photography-content h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.intro {
  font-size: 1.125rem;
  color: #4b5563;
  line-height: 1.6;
  margin-bottom: 2rem;
}

/* Photo Grid */
.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.photo-item {
  position: relative;
  cursor: pointer;
}

/* Hover container to apply the hover effect */
.photo-hover-container {
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.photo-img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  pointer-events: none; /* Disable pointer events on the image itself */
  -webkit-user-drag: none;
}

/* Apply hover effect to the container */
.photo-hover-container:hover {
  transform: scale(1.05);
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dimming effect */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
}

.modal-content {
  position: relative;
  max-width: 80%; /* Limit the width to prevent overly large images */
  max-height: 80%; /* Limit the height for better centering */
  background: none;
  cursor: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  animation: zoomIn 0.3s ease;
  pointer-events: none;
  -webkit-user-drag: none;
}

/* Adjust centering and scaling */
@media (min-width: 768px) {
  .modal-content {
    max-width: 70%;
    max-height: 70%;
  }
}

/* Zoom Animation */
@keyframes zoomIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

/* Next and Previous Buttons */
.prev-btn, .next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s;
  border-radius: 50%;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.prev-btn:hover, .next-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
