/* Font Face Declaration */
@font-face {
    font-family: 'Surfing Capital';
    src: url('./fonts/SurfingCapital.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
  }
  
  /* Global Styles */
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  body {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
  }
  
  /* Layout Containers */
  .portfolio-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* background-color: white; */ /* toggle this to show video or not!!! */
  }
  
  .fullscreen-page {
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
  
  .fullscreen-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    filter: saturate(50%); /* Reduces saturation to 50% */
  }
  
  /* Header Styles */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    /* position: relative; */
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .logo-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .logo-button:hover {
    transform: scale(1.1);
  }
  
  .logo-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .company-name {
    font-weight: bold;
  }
  
  /* Navigation Styles */
  .nav {
    display: flex;
    gap: 1.5rem;
  }
  
  .nav-link {
    text-decoration: none;
    color: #4b5563;
    position: relative;
    padding-bottom: 5px;
    transition: color 0.3s ease;
  }
  
  .nav-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #002FA7;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .nav-link:hover {
    color: #1f2937;
  }
  
  .nav-link:hover::after {
    transform: scaleX(1);
  }
  
  /* Main Content Styles */
  .main-content {
    display: grid;
    grid-template-columns: 1fr 2fr; /* Set a 1:2 ratio for the grid layout */
    gap: 1rem; /* Adds space between the grid columns */
    align-items: center;
    padding: 15rem 2rem; /* Adjust padding to suit the new grid layout */
    position: relative;
    z-index: 1;
  }
  
  .text-content {
    max-width: 100%; /* Ensure it uses the allocated space */
    background-color: rgba(255, 255, 255, 0.7);
    padding: 2rem;
    border-radius: 10px;
  }
  
  .name {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #002FA7;
  }
  
  .surfing-capital-font {
    font-family: 'Surfing Capital', sans-serif;
    font-size: 5rem;
    font-weight: normal;
  }
  
  .subtitle {
    color: #4b5563;
    margin-bottom: 1rem;
  }
  
  .description {
    margin-bottom: 1.5rem;
  }
  
  /* Social Icons */
  .social-icons {
    display: flex;
    gap: 1rem;
  }
  
  .social-icons svg {
    cursor: pointer;
    color: #4b5563;
    transition: color 0.3s ease;
  }
  
  .social-icons svg:hover {
    color: #1f2937;
  }
  
  /* Projects Showcase */
  .projects-showcase {
    padding: 2rem;
    background-color: #f3f4f6;
  }
  
  .project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .project-card {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    transition: opacity 0.5s, transform 0.5s;
  }
  
  /* Footer Styles */
  .footer {
    /* background-color: rgba(185, 217, 235, 1.0); */
    padding: 1rem 0;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    /* padding: 1rem; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .footer-nav {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
  
  .copyright {
    font-size: 0.75rem;
    color: #4b5563;
    align-self: flex-end; /* Aligns the copyright to the right */
    margin-top: auto; /* Pushes it to the bottom of the footer */
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .nav {
      margin-top: 1rem;
    }
  
    .main-content {
        grid-template-columns: 1fr; /* Stacks the content vertically on smaller screens */
        gap: 1rem;
        padding: 2rem 1rem;
      }
  
    .text-content {
    max-width: 100%;
    }
  
    .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 0.5rem;
    }
  
    .footer-nav {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  