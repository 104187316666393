/* General Styles */
.about-page-container {
    font-family: Arial, sans-serif;
    /* padding: 2rem; */
    /* max-width: 1200px; Set a maximum width for the content */
    margin: 0 auto; /* Center the content horizontally */
    padding-top: 6rem; /* Ensure content is not hidden under the fixed header */
  }
  
  /* About Content Styles */
  .about-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: left; /* Align text to the left */
  }
  
  .about-content h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: center; /* Center the title */
  }
  
  .about-content p {
    font-size: 1.125rem;
    color: #4b5563;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  